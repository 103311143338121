import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';

import type { UsePaypalPaymentErrors, UsePaypalPaymentInterface } from './usePaypalPayment';

import { useApi } from '~/composables/useApi';

/**
 * Allows loading the available payment
 * methods for current cart, and selecting (saving) one of them.
 *
 * See the {@link UsePaypalPaymentInterface} for a list of methods and values available in this composable.
 */
export function usePaypalPayment(): UsePaypalPaymentInterface {
  const context = useContext();
  const { cart } = useCart();
  const loading = ref(false);
  const error = ref<UsePaypalPaymentErrors>({
    token: null,
  });
  const { app } = useContext();
  const { query } = useApi();

  const generateToken = async () => {
    Logger.debug('usePaypalPayment.token');
    let result = null;

    try {
      loading.value = true;

      let returnUrl = app.localeRoute({
        name: 'paypal-order',
      });
      let cancelUrl = app.localeRoute({
        name: 'cart',
      });

      let paypalReturn = returnUrl.fullPath.replace(/^\/+/g, '');
      let paypalCancel = cancelUrl.fullPath.replace(/^\/+/g, '');
      const GENERATE_TOKEN_MUTATION = `
        mutation GENERATE_TOKEN_MUTATION($cartId: String!) {
          createPaypalExpressToken(
            input: {
              cart_id: $cartId
              code: "paypal_express"
              express_button: false
              urls: {
                return_url: "${paypalReturn}"
                cancel_url: "${paypalCancel}"
              }
            }
          ) {
            token
            paypal_urls {
              start
              edit
            }
          }
        }
      `;
      const { data, errors } = await query(GENERATE_TOKEN_MUTATION, { cartId: cart.value.id });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        throw new Error(joinedErrors);
      }
      result = data;
      error.value.token = null;
    } catch (err) {
      error.value.token = err;
      Logger.error('usePaypalPayment/load', err);
    } finally {
      loading.value = false;
    }

    Logger.debug('[Result]:', { result });
    return result;
  };

  return {
    generateToken,

    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './usePaypalPayment';
export default usePaypalPayment;
