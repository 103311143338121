

























































































































import { SfInput, SfButton } from '@storefront-ui/vue';
import { ref, defineComponent, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, email, confirmed } from 'vee-validate/dist/rules';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/modules/customer/helpers/passwordValidation';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import { useGuestUser } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('confirmed', {
  ...confirmed,
  message: 'Value must be matched',
});

extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'CreateAccountCheckoutStep',
  components: {
    ValidationProvider,
    ValidationObserver,
    SfButton,
    SfInput,
  },
  setup(_, { emit }) {
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha,
    );

    const {
      loading,
      register,
      error,
      isCustomerEmailAvailable,
      isAuthenticated,
    } = useUser();
    const {
      attachToCart,
      loading: loadingGuestUser,
      error: errorGuestUser,
    } = useGuestUser();
    const { cart } = useCart();

    type Form = {
      firstname: string;
      lastname: string;
      email: string;
      password: string;
      recaptchaToken?: string;
      recaptchaInstance?: string;
    };

    const form = ref<Form>({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
    });

    const confirmPassword = ref('');

    const handleFormSubmit = (reset: () => void) => async () => {
      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();
        form.value.recaptchaToken = recaptchaToken;
        form.value.recaptchaInstance = $recaptcha;
      }

      // if (form.value.firstname == "" || form.value.lastname) {
      // 	let tempName = form.value.email.substring(0, form.value.email.indexOf("@"));
      // 	form.value.firstname = tempName;
      // 	form.value.lastname = tempName;
      // }

      let result = await isCustomerEmailAvailable(form.value.email);

      // await register({ user: form.value });

      // if (isRecaptchaEnabled.value) {
      // 	$recaptcha.reset();
      // }

      // if (!error.value.register) {
      // 	await mergeItem('checkout', { 'user-account': form.value });
      // 	await mergeItem('checkout', { 'user-account-action': "register" });
      // 	emit('nextStep');

      if (result) {
        if (!isAuthenticated.value) {
          await attachToCart({ email: form.value.email, cart });
        }
        await mergeItem('checkout', { 'user-account': form.value });
        await mergeItem('checkout', { 'user-account-action': 'register' });
        emit('nextStep');
      }

      // 	// window.location.reload(true)
      // }
    };

    return {
      form,
      loading,
      handleFormSubmit,

      isRecaptchaEnabled,

      confirmPassword,
    };
  },
});
