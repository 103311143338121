export interface CheckoutPersonalForm {
  gender: string | number;
  prefix: string;
  firstname: string;
  lastname: string;
  telephone: string;
  marketing_note: string;
  email?: string;
  password?: string;
}

export const getInitialCheckoutPersonalForm = (): CheckoutPersonalForm => ({
  gender: '',
  prefix: '',
  firstname: '',
  lastname: '',
  telephone: '',
  marketing_note: '',
});
