











































































































































import { useMainNavStore } from '@/stores/mainNavStore';
import {
  useContext,
  useRouter,
  defineComponent,
  ref,
  watch,
  useFetch,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { SfInput, SfButton, SfLoader, SfLink } from '@storefront-ui/vue';
import { LoginFormFields, FormName, LoginFormName } from './types';
import { useUser } from '~/modules/customer/composables/useUser';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  components: {
    SfInput,
    SfButton,
    SfLoader,
    SfLink,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    hideHeadlineWrap: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
    checkoutPage: {
      type: Boolean,
      default: false,
    },
    formType: {
      type: String,
      default: 'login' as LoginFormName,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { app } = useContext();
    const { toggleLoginModal, isRestrictedPage } = useUiState();
    const mainNavStore = useMainNavStore();
    const showDocCheckLogin = computed(() =>
      props.checkoutPage ? false : mainNavStore.showDocCheckLogin,
    );

    const { load, loading: loadingUser, user, isAuthenticated } = useUser();

    const formCopy = ref<LoginFormFields>();
    watch(
      () => props.form,
      (newForm) => {
        formCopy.value = { ...newForm };
      },
      { immediate: true, deep: true },
    );
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    watch(isAuthenticated, () => {
      if (isAuthenticated.value) {
        try {
          // app.$gtm.push({ event: 'login' })
        } catch (e) {}
      }
    });

    const docCheckUrl = ref('https://login.doccheck.com/code/2000000007654/');
    useFetch(async () => {
      if (user.value === null) {
        await load();
      }

      if (isAuthenticated.value) {
        docCheckUrl.value = docCheckUrl.value + 'email=' + user?.value?.email;
      }
    });

    const getPageUrl = () => {
      return window.location.href;
    };

    return {
      docCheckUrl,
      isRestrictedPage,
      isAuthenticated,
      user,
      showDocCheckLogin,
      formCopy,
      changeForm,
      getPageUrl,
    };
  },
});
