export default `
mutation OrderAttributes($cartId: String!, $storeCode: String!, $referenceNote: String!, $subscriptionStatus: String!, $affiliateReferenceCode: String!) {
  TissoOrderAttribute (
    input: {
      cart_id: $cartId
      store_code: $storeCode
      attributes: [
        { attribute_code: "reference_note", value: $referenceNote }
        { attribute_code: "subscription_status", value: $subscriptionStatus }
        { attribute_code: "affiliate_reference_code", value: $affiliateReferenceCode }
      ]
    }
  )
}
`;
