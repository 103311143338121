






































































































































































































































































































































































































































































import {
  SfHeading,
  SfTable,
  SfCheckbox,
  SfButton,
  SfTextarea,
  SfDivider,
  SfPrice,
  SfProperty,
  SfLink,
  SfImage,
  SfLoader,
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';

import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useImage } from '~/composables';
import useMakeOrder from '~/modules/checkout/composables/useMakeOrder';
import useCart from '~/modules/checkout/composables/useCart';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import { removeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import type {
  BundleCartItem,
  ConfigurableCartItem,
  CartItemInterface,
  SelectedPaymentMethod,
} from '~/modules/GraphQL/types';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import customOrderAttributesGql from '~/customQueries/mutations/setSummaryOrderAttributes.gql';
import paypalFeeGql from '~/customQueries/order/queries/getPaypalFee.gql';
import { getItem } from '~/helpers/asyncLocalStorage';
import { useUser } from '~/modules/customer/composables/useUser';
import { useApi } from '~/composables';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';
import { useUiNotification } from '~/composables/useUiNotification';
import {
  getVAT,
  getVATtext,
  getMonthlysubs,
  getQuarterlysubs,
} from '~/composables/utils/functions';
import type { Country } from '~/modules/GraphQL/types';
import { useCountrySearch } from '~/composables';
import addressGetter from '~/modules/customer/getters/addressGetter';
import usePaypalPayment from '~/modules/checkout/composables/usePaypalPayment';

import StripePaymentPopup from '~/modules/checkout/components/Payment/StripePaymentPopup.vue';

export default defineComponent({
  name: 'SummaryCheckoutStep',
  components: {
    SfHeading,
    SfTable,
    SfCheckbox,
    SfButton,
    SfDivider,
    SfPrice,
    SfProperty,
    SfLink,
    SfImage,
    SfTextarea,
    SfLoader,
    StripePaymentPopup,
  },
  setup(_, { emit }) {
    const { cart, load, setCart } = useCart();
    const order = ref(null);
    const { make, loading, error: makeOrderErrors } = useMakeOrder();
    const { app } = useContext();
    const router = useRouter();
    const {
      error: userError,
      registerPlusAddresses,
      updateUser,
      isAuthenticated,
    } = useUser();
    const { query } = useApi();
    const isPaymentReady = ref(false);
    const terms = ref(false);
    const trustpilot = ref(false);
    const vatText = getVATtext();
    const getAttributes = (product: ConfigurableCartItem) =>
      product.configurable_options || [];
    const getBundles = (product: BundleCartItem) =>
      product.bundle_options?.map((b) => b.values).flat() || [];
    const paymentMethod = ref<SelectedPaymentMethod[]>([]);
    const { selectPaymentMethod } = usePaymentProvider();
    const { selectedStore, selectedCurrency } = useMagentoConfiguration();
    const { send: sendNotification, notifications } = useUiNotification();
    const { load: loadCountries } = useCountrySearch();
    const { generateToken: generatePaypalToken, error: paypalErrors } =
      usePaypalPayment();
    const { getMagentoImage, imageSizes } = useImage();

    const countries = ref<Country[]>([]);
    const countriesList = computed(() =>
      addressGetter.countriesList(countries.value),
    );
    const getCurrency = computed(() => {
      return cartGetters.getCurrency(cart.value);
    });

    const orderComment = ref('');
    const shippingCountryCode = ref('');
    const shippingCost = ref(0.0);
    const paypalFee = ref(null);

    const getGroupedItems = (items) => {
      let groupedItems = {
        onetime: [],
        sub: [],
      };

      for (let item of items) {
        //@ts-ignore
        let customOption = cartGetters.getCustomOptionLabel(item);
        if (customOption) {
          groupedItems['sub'].push(item);
        } else {
          groupedItems['onetime'].push(item);
        }
      }

      return groupedItems;
    };

    const loadPaypalFee = async () => {
      if (!cart.value?.id) return null;

      const result = await query(paypalFeeGql, { cartId: cart.value.id });

      // @ts-ignore
      let prices = result?.data?.cart?.prices?.paypal_fee || null;

      return prices;
    };

    const mountLoading = ref(true);
    const isStripePopupVisible = ref(false);
    const isStripePaymentConfirmed = ref(false);

    const dlPurchaseEvent = () => {
      if (cart.value == null) return;

      try {
        let dataLayerProducts = [];
        let orderNumber = order?.value?.order?.order_number || '';
        // let isAnySub = false;
        let totalAmmount = cart?.value?.prices?.grand_total?.value || 0.0;

        cart.value.items.forEach((el) => {
          // @ts-ignore
          // prettier-ignore
          let isAnySub = el.customizable_options && el.customizable_options.length > 0;

          let productObj = {
            item_id: el.product.sku,
            item_name: el.product.name,
            price: parseFloat(
              (cartGetters.getItemPrice(el).regular / el.quantity).toFixed(2),
            ),
            quantity: el.quantity,
            item_variant: isAnySub ? 'subscription' : 'onetime',
          };

          dataLayerProducts.push(productObj);
        });

        let taxes = 0.0;
        let couponCode = '';
        let shippingCost = 0.0;

        if (
          cart.value.shipping_addresses != null &&
          cart.value.shipping_addresses.length > 0
        ) {
          shippingCost =
            cart.value.shipping_addresses[0].selected_shipping_method.amount
              .value;
        }
        if (
          cart.value.prices.applied_taxes != null &&
          cart.value.prices.applied_taxes.length > 0
        ) {
          cart.value.prices.applied_taxes.forEach((el) => {
            taxes += el.amount.value;
          });
        }
        if (
          cart.value.applied_coupons != null &&
          cart.value.applied_coupons.length > 0
        ) {
          couponCode = cart.value.applied_coupons[0].code;
        }

        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: 'purchase',
          // purchase_type: (isAnySub) ? 'subscription' : "onetime",
          // prescription_code: 'xxxx',
          ecommerce: {
            tax: taxes,
            shipping: shippingCost,
            coupon: couponCode, //promotion or discount code if applicable
            currency: selectedCurrency.value, // 3 letter currency Required
            value: totalAmmount, // total value Required
            transaction_id: orderNumber, // real transaction Id recorded in the backe Required
            items: dataLayerProducts,
          },
        });
      } catch (e) {}
    };

    const isStripePayment = () => {
      // @ts-ignore
      // prettier-ignore
      if (paymentMethod && paymentMethod.value && paymentMethod?.value?.code == 'stripe_payments'
      ) {
        return true;
      }
      return false;
    };

    const processOrderLoading = ref(false);
    const processOrder = async () => {
      if (isStripePayment() && !isStripePaymentConfirmed.value) {
        isStripePopupVisible.value = true;
        return false;
      }

      processOrderLoading.value = true;
      let cartId = cart?.value !== null ? cart?.value?.id : '';

      try {
        // Save custom order attributes
        const response = await query(customOrderAttributesGql, {
          cartId: cart.value.id,
          storeCode: selectedStore.value,
          orderComment: orderComment.value,
          hasTrustpilotConsent: trustpilot.value ? '1' : '0',
        });
        console.log('Attribute query sent', response);

        let isAnyError = false;
        let isRedirected = false;

        // @ts-ignore
        if (paymentMethod.value.code == 'paypal_express') {
          let tokenData = await generatePaypalToken();
          if (tokenData) {
            // @ts-ignore
            if (tokenData.createPaypalExpressToken !== null) {
              // @ts-ignore
              let link = tokenData.createPaypalExpressToken?.paypal_urls?.start;
              // @ts-ignore
              let token = tokenData.createPaypalExpressToken?.token;

              isRedirected = true;
              window.location.href = link;
            } else {
              isAnyError = true;
            }
          } else {
            isAnyError = true;
          }

          if (paypalErrors.value.token !== null) {
            throw new Error(paypalErrors.value.token.message);
          }

          if (isAnyError) {
            throw new Error('Unexpected Error in generate token.');
          }
        }

        if (isRedirected) {
          mountLoading.value = true;
          return false;
        } else {
          order.value = await make();

          if (makeOrderErrors.value.make !== null) {
            throw new Error(makeOrderErrors.value.make.message);
          }
          if (order.value == null) {
            throw new Error('Place order failed, unexpected issue.');
          }

          // register user after order
          let checkout = await getItem('checkout');
          let actionType = checkout ? checkout['user-account-action'] : '';

          if (isAuthenticated.value) {
            let personalDetails = checkout ? checkout['personal-data'] : null;

            if (personalDetails !== null && personalDetails?.value !== null) {
              await updateUser({
                user: {
                  firstname: personalDetails.value.firstname,
                  lastname: personalDetails.value.lastname,
                  prefix: personalDetails.value.prefix,
                  phonenumber: personalDetails.value.telephone,
                  marketing_note: personalDetails.value.marketing_note,
                  // @ts-ignore
                  gender: personalDetails.value.gender || 0,
                },
              });
            }
          }

          if (actionType == 'register') {
            let userData = checkout['user-account'];
            let addressArr = checkout['user-account-address'];

            userData.phonenumber = userData.telephone;

            await registerPlusAddresses({
              user: userData,
              addressCode: addressArr,
            });

            if (userError.value.register !== null) {
              throw new Error(userError.value.register.message);
            }
          }

          // datalayer pushed
          dlPurchaseEvent();

          setCart(null);
          // @ts-ignore
          app.$vsf.$magento.config.state.removeRefCode();
          app.$vsf.$magento.config.state.removeCartId();

          // await load();
          await removeItem('checkout');

          const thankYouRoute = app.localeRoute({
            name: 'typo3',
            params: { slug: 'checkout-thank-you' },
            query: {
              token: cartId,
            },
          });
          await router.push(thankYouRoute);
        }
      } catch (e) {
        console.log('Error: ', e);

        sendNotification({
          icon: 'error',
          id: Symbol(`place_order_error`),
          message: e.message,
          persist: true,
          title: 'Order Processing error',
          type: 'danger',
        });
      } finally {
        processOrderLoading.value = false;
      }
    };

    const discounts = computed(() => cartGetters.getDiscounts(cart.value));
    const appliedCoupon = computed(() =>
      cartGetters.getAppliedCoupon(cart.value),
    );
    const hasDiscounts = computed(() => discounts.value.length > 0);
    const discountsAmount = computed(
      () => -1 * discounts.value.reduce((a, el) => el.value + a, 0),
    );

    const getRowTotal = (product: CartItemInterface) =>
      cartGetters.getItemPrice(product).regular -
      cartGetters.getItemPrice(product).special;

    const getCountryLbl = (code) => {
      let label = '';
      countriesList.value.forEach((el) => {
        if (el.id == code) {
          label = el.label;
          return false;
        }
      });

      if (label == '') {
        return code;
      }

      return label;
    };

    const editAddressInfo = () => {
      emit('editAddress');
    };

    const closeStripePopup = () => {
      isStripePopupVisible.value = false;
    };

    const continueOrderProcess = (status) => {
      if (status) {
        isStripePopupVisible.value = false;
        isStripePaymentConfirmed.value = true;
        processOrder();
      }
    };

    onMounted(async () => {
      const validStep = await isPreviousStepValid('billing');
      if (!validStep) {
        await router.push(app.localePath('/checkout/'));
      }
      let loadedCountries = await loadCountries();
      countries.value = loadedCountries;

      paymentMethod.value = await selectPaymentMethod();

      getGroupedItems(cartGetters.getItems(cart.value));

      if (cart.value != null) {
        if (cart.value.shipping_addresses.length > 0) {
          shippingCountryCode.value =
            cart.value.shipping_addresses[0].country?.code;
          shippingCost.value =
            cart.value.shipping_addresses[0].selected_shipping_method?.amount
              ?.value || 0.0;
        }
      }

      paypalFee.value = await loadPaypalFee();

      // load cart again for latest custom options
      await load();

      mountLoading.value = false;
    });

    return {
      appliedCoupon,
      getCurrency,
      editAddressInfo,

      getMonthlysubs,
      getQuarterlysubs,

      shippingCountryCode,
      shippingCost,
      orderComment,

      mountLoading,
      getCountryLbl,
      processOrderLoading,
      paymentMethod,
      cart,
      cartGetters,
      discounts,
      hasDiscounts,
      discountsAmount,
      getShippingMethodPrice,
      isPaymentReady,
      loading,
      processOrder,
      groupProducts: computed(() =>
        getGroupedItems(cartGetters.getItems(cart.value)),
      ),
      shippingAddress: computed(() =>
        cart?.value?.shipping_addresses.length > 0
          ? cart?.value?.shipping_addresses[0]
          : null,
      ),
      billingAddress: computed(() => cart.value?.billing_address),
      selectedShippingMethod: computed(() =>
        cartGetters.getSelectedShippingMethod(cart.value),
      ),
      // availablePaymentMethods: computed(() => cartGetters.getAvailablePaymentMethods(cart.value)),
      tableHeaders: ['Description', 'Quantity', 'Amount'],
      terms,
      trustpilot,
      totals: computed(() => cartGetters.getTotals(cart.value)),
      getAttributes,
      getBundles,
      getMagentoImage,
      imageSizes,
      getRowTotal,
      getVAT,
      vatText,

      paypalFee,

      closeStripePopup,
      isStripePopupVisible,
      isStripePayment,
      continueOrderProcess,
    };
  },
  computed: {
    getLanguage() {
      var locale = this.$i18n.localeProperties.code;
      return locale === 'de' || locale === 'de_ch';
    },
  },
});
