






































































































































import { SfHeading, SfInput, SfButton, SfCheckbox } from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  useFetch,
  onMounted,
  useRoute,
} from '@nuxtjs/composition-api';
import { required, min, email } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { useGuestUser } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/modules/customer/helpers/passwordValidation';
import LoginForm from '~/modules/customer/components/LoginModal/forms/LoginForm.vue';
import {
  LoginFormFields,
  FormName,
} from '~/modules/customer/components/LoginModal/forms/types';
import { useUiState } from '~/composables';

import usePaypalPayment from '~/modules/checkout/composables/usePaypalPayment';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'InitialCheckoutStep',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,

    LoginForm,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const emailFromUrl = route.value.query.email as string;
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha,
    );
    const { toggleLoginModalWithPassResetForm } = useUiState();

    const { load: loadCart } = useCart();
    const {
      register,
      login,
      loading,
      error: userError,
      isAuthenticated,
    } = useUser();

    const { generateToken: generatePaypalToken } = usePaypalPayment();

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    const getRecaptchaInfo = async (
      isRecaptchaOn: boolean,
    ): Promise<{ token: string | null; cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(),
          cleanup: () => {
            $recaptcha.reset();
          },
        };
      }
      return { token: null, cleanup: () => {} };
    };

    const loginForm = ref<LoginFormFields>({
      email: emailFromUrl || '',
      password: '',
    });

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      loginForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value,
      );
      await login({
        user: { ...form, ...(token ? { recaptchaToken: token } : {}) },
      });
      cleanup();

      if (!userError.value.login) {
        // user logged in

        // await Promise.all([loadItemsCount(), loadCart()]);
        await mergeItem('checkout', { 'user-account-action': 'login' });

        const cartLink = app.localeRoute({ name: 'cart' });
        window.location.href = cartLink.fullPath;

        // window.location.reload();
        // window.location.href = ;
        // await router.push(app.localeRoute({ name: 'cart' }));
      }
    };

    const changeLoginForm = (val) => {
      if (val == 'forgotPassword') {
        toggleLoginModalWithPassResetForm();
      }
    };

    return {
      changeLoginForm,
      isAuthenticated,
      loading,
      goToCart,
      loginForm,
      onLoginFormSubmit,
      isRecaptchaEnabled,
    };
  },
});
