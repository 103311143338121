var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"createAccStep"},[_c('div',{staticClass:"headWrap"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Create an account')))]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('Start setting up your account by adding the following details'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"bodyWrap"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var validate = ref.validate;
return [_c('form',{staticClass:"form form--checkout form_registration",attrs:{"autocomplete":"off","name":"form_registration","novalidate":""},on:{"submit":function($event){$event.preventDefault();validate().then(handleSubmit(_vm.handleFormSubmit(reset)))}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element custom_input",attrs:{"placeholder":_vm.$t('name.surname@email.com'),"label":_vm.$t('Email address *'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element custom_input",attrs:{"valid":!errors[0],"label":_vm.$t('Create password *'),"error-message":_vm.$t(errors[0]),"type":"password","placeholder":"*****"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element custom_input",attrs:{"valid":!errors[0],"label":_vm.$t('Repeat password *'),"error-message":_vm.$t(errors[0]),"type":"password","placeholder":"******"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"button__area"},[_c('SfButton',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v("\n              "+_vm._s(_vm.$t('Continue'))+"\n            ")])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }