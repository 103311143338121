














































































import { SfInput, SfButton, SfLink } from '@storefront-ui/vue';
import {
  ref,
  defineComponent,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, email, confirmed } from 'vee-validate/dist/rules';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/modules/customer/helpers/passwordValidation';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import { useGuestUser, useUiState } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('confirmed', {
  ...confirmed,
  message: 'Value must be matched',
});

extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'GuestCheckoutStep',
  components: {
    ValidationProvider,
    ValidationObserver,
    SfButton,
    SfInput,
    SfLink,
  },
  setup(_, { emit }) {
    const { toggleLoginModalGuestCheckout } = useUiState();
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha,
    );
    const isEmailAvailable = ref(true);

    const {
      attachToCart,
      loading: loadingGuestUser,
      error: errorGuestUser,
    } = useGuestUser();

    const { cart } = useCart();

    const {
      loading: loadingUser,
      isAuthenticated,
      isCustomerEmailAvailable,
    } = useUser();

    const loading = computed(() => loadingGuestUser.value || loadingUser.value);
    const anyError = computed(() => errorGuestUser.value.attachToCart);

    type Form = {
      firstname: string;
      lastname: string;
      email: string;
      recaptchaToken?: string;
      recaptchaInstance?: string;
    };

    const form = ref<Form>({
      firstname: '',
      lastname: '',
      email: '',
    });

    const confirmPassword = ref('');

    const handleFormSubmit = (reset: () => void) => async () => {
      if (!isAuthenticated.value) {
        // check if email is available
        isEmailAvailable.value = await isCustomerEmailAvailable(
          form.value.email,
          false,
        );

        await attachToCart({ email: form.value.email, cart });
        if (!isEmailAvailable.value) {
          toggleLoginModalGuestCheckout(form.value.email);
        }
      }

      if (!anyError.value) {
        await mergeItem('checkout', { 'user-account-action': 'guest' });
        await mergeItem('checkout', { 'user-account': form.value });
        // await router.push(app.localeRoute({ name: 'shipping' }));
        reset();
        emit('nextStep');
      }
    };

    return {
      form,
      loading,
      handleFormSubmit,
      isRecaptchaEnabled,
      isEmailAvailable,
      confirmPassword,
    };
  },
});
