export default `
mutation OrderAttributes($cartId: String!, $storeCode: String!, $orderComment: String!, $hasTrustpilotConsent: String!) {
  TissoOrderAttribute (
    input: {
      cart_id: $cartId
      store_code: $storeCode
      attributes: [
        { attribute_code: "tisso_order_comment", value: $orderComment }
        { attribute_code: "trustpilot_consent", value: $hasTrustpilotConsent }
      ]
    }
  )
}`;
