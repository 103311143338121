




























































































































































































































































































































































































































































































































































































































































































































import base64 from 'base-64';
import {
  ref,
  computed,
  onMounted,
  defineComponent,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { useApi, useUiNotification } from '~/composables';
import ShipmentCheckoutStep from '~/modules/checkout/components/Steps/ShipmentCheckoutStep.vue';
import BillingCheckoutStep from '~/modules/checkout/components/Steps/BillingCheckoutStep.vue';

import {
  SfHeading,
  SfInput,
  SfButton,
  SfSelect,
  SfCheckbox,
  SfTextarea,
  SfNotification,
} from '@storefront-ui/vue';
import { required, min, digits } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import userShippingGetters from '~/modules/customer/getters/userShippingGetters';
import addressGetter from '~/modules/customer/getters/addressGetter';
import { useCountrySearch } from '~/composables';
import type {
  Country,
  AvailableShippingMethod,
  CustomerAddress,
  Customer,
} from '~/modules/GraphQL/types';
import useShipping from '~/modules/checkout/composables/useShipping';
import useBilling from '~/modules/checkout/composables/useBilling';
import useUser from '~/modules/customer/composables/useUser';
import useUserAddress from '~/modules/customer/composables/useUserAddress';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';
import {
  addressFromApiToForm,
  CheckoutAddressForm,
  getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';
import {
  getInitialCheckoutPersonalForm,
  CheckoutPersonalForm,
} from '~/helpers/checkout/personal';
import { mergeItem, getItem } from '~/helpers/asyncLocalStorage';
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';

import { useGuestUser } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import customOrderAttributesGql from '~/customQueries/mutations/setPersonalOrderAttributes.gql';
import checkCustomerDataConsistency from '~/customQueries/mutations/checkCustomerDataConsistency.gql';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number',
});

export default defineComponent({
  name: 'PersonalDetailCheckoutStep',
  components: {
    ShipmentCheckoutStep,
    BillingCheckoutStep,

    SfHeading,
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    SfTextarea,
    SfNotification,

    ValidationProvider,
    ValidationObserver,
    UserShippingAddresses: () =>
      import('~/modules/checkout/components/UserShippingAddresses.vue'),
    VsfShippingProvider: () =>
      import('~/modules/checkout/components/VsfShippingProvider.vue'),
  },
  setup(_, { emit }) {
    const { query } = useApi();
    const router = useRouter();
    const { app } = useContext();
    const { selectedStore, selectedLocale } = useMagentoConfiguration();

    const userShipping = ref<Customer | null>(null);
    const userBilling = ref<Customer | null>(null);
    const { state } = app.context.$vsf.$magento.config;
    const { send: sendNotification, notifications } = useUiNotification();

    const {
      load: loadShipping,
      save: saveShipping,
      error: shippingErrors,
      loading: isShippingLoading,
    } = useShipping();
    const {
      save: saveBilling,
      load: loadBilling,
      error: billingErrors,
      loading: isBillingLoading,
    } = useBilling();
    const { load: loadUserAddress, setDefaultAddress } = useUserAddress();
    const {
      user,
      loading: userLoading,
      register: userRegister,
      error: userError,
      updateUser,
      load: userLoad,
      isAuthenticated,
    } = useUser();
    const { load: loadCountries, search: searchCountry } = useCountrySearch();
    const {
      save: saveShippingProvider,
      error: errorShippingProvider,
      loading: isSpLoading,
    } = useShippingProvider();

    const {
      attachToCart,
      loading: loadingGuestUser,
      error: errorGuestUser,
    } = useGuestUser();
    const { cart } = useCart();

    const countries = ref<Country[]>([]);
    const country = ref<Country | null>(null);

    const personalDetails = ref<CheckoutPersonalForm>(
      getInitialCheckoutPersonalForm(),
    );
    const billingDetails = ref<CheckoutAddressForm>(
      getInitialCheckoutAddressForm(),
    );

    const isLoading =
      isShippingLoading.value ||
      isBillingLoading.value ||
      userLoading.value ||
      isSpLoading.value;

    const shippingDetails = ref<CheckoutAddressForm>(
      getInitialCheckoutAddressForm(),
    );
    const shippingMethods = ref<AvailableShippingMethod[]>([]);

    const currentBillingAddressId = ref<number | null>(null);
    const currentShippingAddressId = ref<number | null>(null);

    const isSetAsDefaultRequested = ref(false);
    const isFormSubmitted = ref(false);
    const isAddNewAddressFormVisible = ref(true);

    const isShippingDetailsStepCompleted = ref(false);
    const isPersoanlDetailsStepCompleted = ref(false);
    const isBillingDetailsStepCompleted = ref(false);

    const addresses = computed(() =>
      userShippingGetters.getAddresses(userShipping.value),
    );
    const countriesList = computed(() =>
      addressGetter.countriesList(countries.value),
    );
    // const countriesList = computed(() => {
    // 	// #TS-704 : ch shop only CH country
    // 	return ["de_ch","en_ch","fr_ch"].includes(selectedStore.value) ?
    // 		[{id: 'CH', label: app.i18n.t('Switzerland') as string, englishLabel: 'Switzerland', abbreviation: 'CH'}]
    // 		: addressGetter.countriesList(countries.value);
    // });
    const isBillingCompany = ref(false);
    const isShippingCompany = ref(false);

    const haveSubProds = ref(false);

    const canMoveForward = computed(
      () =>
        !isShippingLoading.value &&
        shippingDetails.value &&
        Object.keys(shippingDetails.value).length > 0 &&
        !isBillingLoading.value &&
        billingDetails.value &&
        Object.keys(billingDetails.value).length > 0 &&
        isShippingDetailsStepCompleted &&
        isBillingDetailsStepCompleted,
    );

    const step1Action = ref('');

    const isShippingStep = ref(true);

    const isDiffShippingAddr = ref(false);

    const popupVisible = ref(false);

    const showBillingInfo = () => {
      isShippingStep.value = false;
    };

    const handle = () => {
      emit('nextStep');
    };

    const changeShippingDetails = (
      field: keyof CheckoutAddressForm,
      value: string,
    ) => {
      shippingDetails.value[field] = value;
      isShippingDetailsStepCompleted.value = false;
      currentShippingAddressId.value = null;
    };
    const changePersonalDetails = (
      field: keyof CheckoutPersonalForm,
      value: string,
    ) => {
      personalDetails.value[field] = value;
      isPersoanlDetailsStepCompleted.value = false;

      // if (field == "firstname" || field == "lastname" || field == "telephone") {
      // 	changeShippingDetails(field, value);
      // 	changeBillingDetails(field, value);
      // }
    };
    const changeBillingDetails = (
      field: keyof CheckoutAddressForm,
      value: string,
    ) => {
      billingDetails.value[field] = value;

      if (!isDiffShippingAddr.value) {
        changeShippingDetails(field, value);
      }

      currentBillingAddressId.value = null;
      isBillingDetailsStepCompleted.value = false;
    };

    const getRefCode = () => {
      return state.getRefCode() || '';
    };
    const setSubscriptionStatus = () => {
      if (cart !== null && cart.value !== null && cart?.value?.items) {
        if (cart?.value?.items?.length > 0) {
          cart?.value?.items?.forEach((el) => {
            // @ts-ignore
            if (el.customizable_options && el.customizable_options.length > 0) {
              haveSubProds.value = true;
            }
          });
        }
      }
    };

    const submitInProgress = ref(false);
    const handleAllInfoSubmit = (reset: () => void) => async () => {
      //checkCountryRedirect(billingDetails.value.country_code);
      if (popupVisible.value) return false;

      if (submitInProgress.value) return false;

      let anyError = false;
      submitInProgress.value = true;

      let saveInBook = false;

      try {
        const checkout = await getItem('checkout');

        if (step1Action.value == '') {
          step1Action.value = checkout ? checkout['user-account-action'] : '';
        }

        let formData = checkout ? checkout['user-account'] : '';

        if (step1Action.value == 'register') {
          personalDetails.value.email = formData.email;
          personalDetails.value.password = formData.password;
        }

        // if (personalDetails.value.telephone == "") {
        // 	billingDetails.value.telephone = "00000000";
        // 	shippingDetails.value.telephone = "00000000";
        // }

        // if (billingDetails.value.telephone == "") {
        // 	billingDetails.value.telephone = "00000000";
        // }
        // if (!shippingDetails.value.telephone) {
        // 	shippingDetails.value.telephone = (personalDetails.value.telephone) ? personalDetails.value.telephone : "00000000";
        // }

        // save personal data
        await mergeItem('checkout', { 'personal-data': personalDetails });

        // check for billing shipping default fields
        if (!billingDetails.value.firstname) {
          billingDetails.value.firstname = personalDetails.value.firstname;
        }
        if (!billingDetails.value.lastname) {
          billingDetails.value.lastname = personalDetails.value.lastname;
        }
        if (!shippingDetails.value.firstname) {
          shippingDetails.value.firstname = billingDetails.value.firstname;
        }
        if (!shippingDetails.value.lastname) {
          shippingDetails.value.lastname = billingDetails.value.lastname;
        }

        if (step1Action.value == 'register') {
          saveInBook = true;
          // check for login
          // if (!isAuthenticated.value) {
          // 	await userLoad();
          // }

          let userEmail = personalDetails.value.email;

          await mergeItem('checkout', {
            'user-account': personalDetails.value,
          });

          let billAddressDetails = <CustomerAddress>{
            firstname: billingDetails.value.firstname,
            lastname: billingDetails.value.lastname,
            street: [
              billingDetails.value.street,
              billingDetails.value.apartment,
            ],
            postcode: billingDetails.value.postcode,
            city: billingDetails.value.city,
            company: billingDetails.value.company,
            country_code: billingDetails.value.country_code,
            telephone: billingDetails.value.telephone,
            default_billing: true,
            default_shipping: !isDiffShippingAddr.value,
          };

          let addressArr = [];
          addressArr.push(billAddressDetails);

          if (isDiffShippingAddr.value) {
            let shippAddressDetails = <CustomerAddress>{
              firstname: shippingDetails.value.firstname,
              lastname: shippingDetails.value.lastname,
              company: shippingDetails.value.company,
              street: [
                shippingDetails.value.street,
                shippingDetails.value.apartment,
              ],
              postcode: shippingDetails.value.postcode,
              city: shippingDetails.value.city,
              country_code: shippingDetails.value.country_code,
              telephone: shippingDetails.value.telephone,
              default_shipping: true,
              default_billing: false,
            };

            addressArr.push(shippAddressDetails);
          }

          let addrsData1 = base64.encode(JSON.stringify(addressArr));
          await mergeItem('checkout', { 'user-account-address': addrsData1 });

          // register on last setup
          // attached as guest user
          if (!isAuthenticated.value) {
            await attachToCart({ email: userEmail, cart });
          }

          // const {data, errors} = await query(
          // 	attachAddressToCustomerGql,
          // 	{
          // 		email: userEmail,
          // 		storeCode: selectedStore.value,
          // 		address: jsonParam
          // 	}
          // );

          // console.log("Address Attach Result: ", data, errors);

          // attach user to cart
          // if (!isAuthenticated.value) {
          // 	await attachToCart({email: userEmail, cart});
          // }
        }

        // for personal form
        isPersoanlDetailsStepCompleted.value = true;

        const billingAddressId = currentBillingAddressId.value;
        const shippingAddressId = currentShippingAddressId.value;

        const shippingDetailsData = {
          ...shippingDetails.value,
          customerAddressId: shippingAddressId,
          save_in_address_book: saveInBook,
        };
        await mergeItem('checkout', { shipping: shippingDetailsData });

        const shippingInfo = await saveShipping({
          shippingDetails: shippingDetailsData,
        });
        shippingMethods.value = shippingInfo?.available_shipping_methods ?? [];
        isShippingDetailsStepCompleted.value = true;

        if (shippingErrors.value.save !== null) {
          anyError = anyError || true;
          // throw (shippingErrors.value.save);
        }

        // for shipping
        // if (shippingAddressId !== null && isSetAsDefaultRequested.value) {
        // 	const [chosenAddress] = userShippingGetters.getAddresses(
        // 		userShipping.value,
        // 		{id: shippingAddressId},
        // 	);

        // 	chosenAddress.default_shipping = isSetAsDefaultRequested.value;

        // 	if (chosenAddress) {
        // 		await setDefaultAddress({address: chosenAddress});
        // 		userShipping.value = await loadUserAddress(true);
        // 	}
        // }

        // if only one continue to billing address
        if (shippingMethods.value.length >= 1) {
          let selectedMethod = shippingMethods.value[0];

          if (
            shippingMethods.value.length > 1 &&
            cart?.value !== null &&
            cart?.value?.id
          ) {
            let isAnySub = false;
            cart.value?.items?.forEach((el) => {
              // @ts-ignore
              isAnySub = isAnySub || el.customizable_options?.length > 0;
            });

            if (
              shippingMethods.value !== null &&
              shippingMethods.value.length > 0
            ) {
              shippingMethods.value.forEach((el) => {
                if (el.available) {
                  if (isAnySub && el.method_code == 'freeshipping') {
                    selectedMethod = el;
                    return false;
                  }
                  if (!isAnySub && el.method_code != 'freeshipping') {
                    selectedMethod = el;
                    return false;
                  }
                }
              });
            }
          }

          let shippingData = {
            carrier_code: selectedMethod.carrier_code,
            method_code: selectedMethod.method_code,
          };

          await saveShippingProvider({ shippingMethod: shippingData });

          if (errorShippingProvider.value.save !== null) {
            // throw (shippingErrors.value.save);
            anyError = anyError || true;
          }

          const billingDetailsData = {
            billingDetails: {
              ...billingDetails.value,
              customerAddressId:
                billingAddressId === null ? null : String(billingAddressId),
              sameAsShipping: !isDiffShippingAddr.value,
              save_in_address_book: saveInBook,
            },
          };
          await saveBilling(billingDetailsData);
          await mergeItem('checkout', { billing: billingDetailsData });

          if (billingErrors.value.save !== null) {
            anyError = anyError || true;
          }

          // for billing
          // if (billingAddressId !== null && isSetAsDefaultRequested.value) {
          // 	const [chosenAddress] = userBillingGetters.getAddresses(
          // 		userBilling.value,
          // 		{id: billingAddressId},
          // 	);
          // 	chosenAddress.default_billing = isSetAsDefaultRequested.value;
          // 	if (chosenAddress) {
          // 		await setDefaultAddress({address: chosenAddress});
          // 		userBilling.value = await loadUserAddress(true);
          // 	}
          // }
          isBillingDetailsStepCompleted.value = true;

          // save custom order attributes
          await query(customOrderAttributesGql, {
            cartId: cart.value.id,
            storeCode: selectedStore.value,
            referenceNote: personalDetails.value.marketing_note,
            subscriptionStatus: haveSubProds.value ? '1' : '0',
            affiliateReferenceCode: getRefCode(),
          });
        } else {
          anyError = anyError || true;
        }

        reset();

        // await router.push(app.localeRoute({ name: 'payment' }));
        if (!anyError) {
          submitInProgress.value = false;
          emit('nextStep');
        }
      } catch (e) {
        console.log('Error: ', e);
      }

      submitInProgress.value = false;
    };

    const changeGender = (val) => {
      personalDetails.value.gender = val;
    };

    const handleChangeBillingCompany = (val: boolean) => {
      isBillingCompany.value = val;

      if (!isDiffShippingAddr.value) {
        handleChangeShippingCompany(val);
      }
    };
    const handleChangeShippingCompany = (val: boolean) => {
      isShippingCompany.value = val;
    };

    const handleChangeDiffShipping = (val: boolean) => {
      isDiffShippingAddr.value = val;

      if (isDiffShippingAddr.value) {
        shippingDetails.value = getInitialCheckoutAddressForm();
        // shippingDetails.value.firstname = personalDetails.value.firstname;
        // shippingDetails.value.lastname = personalDetails.value.lastname;
        // shippingDetails.value.telephone = personalDetails.value.telephone;
        isShippingCompany.value = false;
      } else {
        shippingDetails.value = billingDetails.value;
        isShippingCompany.value = isBillingCompany.value;
      }
    };

    const checkCountryRedirect = (countryCode: string) => {
      // if ch store
      if (selectedStore.value.indexOf('_ch') !== -1) {
        if (countryCode != 'CH') {
          popupVisible.value = true;
        }
      } else {
        if (countryCode == 'CH') {
          popupVisible.value = true;
        }
      }
    };

    const changeBillingCountry = async (id: string) => {
      changeBillingDetails('country_code', id);

      //checkCountryRedirect(id);
    };

    const changeShippingCountry = async (id: string) => {
      changeShippingDetails('country_code', id);

      // checkCountryRedirect(id);
    };

    const handleSetCurrentBillingAddress = async (
      customerAddress: CustomerAddress,
    ) => {
      const id = customerAddress?.id;
      // currentBillingAddressId.value = id;
      if (id) {
        isAddNewAddressFormVisible.value = false;
      }

      billingDetails.value = addressFromApiToForm(customerAddress);
      // country.value = customerAddress.country_code ? await searchCountry({id: customerAddress.country_code}) : null;

      isBillingCompany.value =
        billingDetails.value &&
        billingDetails.value.company != '' &&
        billingDetails.value.company !== null;
      isBillingDetailsStepCompleted.value = false;

      //checkCountryRedirect(billingDetails.value.country_code);
    };

    const handleSetCurrentShippingAddress = async (
      customerAddress: CustomerAddress,
    ) => {
      const id = customerAddress?.id;
      // currentShippingAddressId.value = id;
      if (id) {
        isAddNewAddressFormVisible.value = false;
      }
      shippingDetails.value = addressFromApiToForm(customerAddress);
      // country.value = customerAddress.country_code ? await searchCountry({id: customerAddress.country_code}) : null;

      isShippingCompany.value =
        shippingDetails.value &&
        shippingDetails.value.company != '' &&
        shippingDetails.value.company !== null;
      isShippingDetailsStepCompleted.value = false;

      // checkCountryRedirect(shippingDetails.value.country_code);
    };

    const goBack = () => {
      emit('backStep', `step1-${step1Action.value}`);
    };

    const actionCartRedirect = async () => {
      let locale = selectedLocale.value;
      if (locale.indexOf('_ch') !== -1) {
        locale = locale.replace('_ch', '');
      } else {
        locale += '_ch';
      }

      // await router.push(app.localeRoute({ name: 'cart' }, locale));

      /* full reload instead of router push because of header states */
      let pathObj = app.localeRoute({ name: 'cart' }, locale);
      window.location.href = pathObj.fullPath;
    };

    onMounted(async () => {
      setSubscriptionStatus();

      const checkout = await getItem('checkout');
      let formData = checkout ? checkout['user-account'] : '';
      step1Action.value = checkout ? checkout['user-account-action'] : '';

      if (step1Action.value == 'register') {
        personalDetails.value = formData;
      }

      if (isAuthenticated.value) {
        if (user.value == null) {
          await userLoad();
        }

        personalDetails.value.firstname =
          step1Action.value != 'register' ? user.value?.firstname : '';
        personalDetails.value.lastname =
          step1Action.value != 'register' ? user.value?.lastname : '';
        personalDetails.value.email = user.value?.email || '';
        personalDetails.value.prefix = user.value?.prefix || '';
        personalDetails.value.gender = user.value?.gender || '';
        personalDetails.value.telephone = user.value?.phonenumber || '';
        personalDetails.value.marketing_note = user.value?.marketing_note || '';
      }
      // else if (step1Action.value == "register" || step1Action.value == "guest") {
      // 	let formData = checkout['user-account'];
      // 	personalDetails.value = merge(personalDetails.value, formData);
      // }

      // if (!validStep) {
      // 	await router.push(app.localePath('/checkout/'));
      // }

      if (isAuthenticated.value) {
        // const [loadedBillingInfoBoundToCart, loadedShippingInfoBoundToCart, loadedUserAddress, loadedCountries] = await Promise.all([
        // 	loadBilling(),
        // 	loadShipping(),
        // 	loadUserAddress(),
        // 	loadCountries()
        // ]);

        const [loadedUserAddress, loadedCountries] = await Promise.all([
          loadUserAddress(),
          loadCountries(),
        ]);
        countries.value = loadedCountries;

        const [defaultBillingAddress = null] = userBillingGetters.getAddresses(
          loadedUserAddress,
          {
            default_billing: true,
          },
        );
        // const wasBillingAddressAlreadySetOnCart = Boolean(loadedBillingInfoBoundToCart);

        // Temp check for data consistency
        if (defaultBillingAddress) {
          query(checkCustomerDataConsistency, {
            billingAddress: {
              firstname: defaultBillingAddress.firstname,
              lastname: defaultBillingAddress.lastname,
              company: defaultBillingAddress.company,
              street: defaultBillingAddress.street,
              postcode: defaultBillingAddress.postcode,
              city: defaultBillingAddress.city,
              telephone: defaultBillingAddress.telephone,
            },
            customerData: {
              firstname: personalDetails.value.firstname,
              lastname: personalDetails.value.lastname,
            },
          });
        }

        const [defaultShippingAddress = null] =
          userShippingGetters.getAddresses(loadedUserAddress, {
            default_shipping: true,
          });
        // const wasShippingAddressAlreadySetOnCart = Boolean(loadedShippingInfoBoundToCart);

        // keep in mind default billing address is set on a customer's cart during cart creation
        // if (wasBillingAddressAlreadySetOnCart) {
        // 	const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
        // 		loadedUserAddress?.addresses,
        // 		loadedBillingInfoBoundToCart,
        // 	);

        // 	handleSetCurrentBillingAddress({...loadedBillingInfoBoundToCart, id: userAddressIdenticalToSavedCartAddress?.id});
        // } else if (defaultBillingAddress) {
        // 	handleSetCurrentBillingAddress(defaultBillingAddress);
        // }
        handleSetCurrentBillingAddress(defaultBillingAddress);

        // if (wasShippingAddressAlreadySetOnCart) {
        // 	const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
        // 		loadedUserAddress?.addresses,
        // 		loadedShippingInfoBoundToCart,
        // 	);
        // 	handleSetCurrentShippingAddress({...loadedShippingInfoBoundToCart, id: userAddressIdenticalToSavedCartAddress?.id});
        // } else if (defaultShippingAddress) {
        // 	handleSetCurrentShippingAddress(defaultShippingAddress);
        // }
        handleSetCurrentShippingAddress(defaultShippingAddress);

        if (
          billingDetails.value?.country_code ||
          shippingDetails.value?.country_code
        ) {
          let code = billingDetails.value.country_code;
          if (!code) {
            code = shippingDetails.value?.country_code;
          }
          country.value = await searchCountry({ id: code });
        }

        // billingDetails.value.firstname = personalDetails.value.firstname;
        // billingDetails.value.lastname = personalDetails.value.lastname;
        // billingDetails.value.telephone = personalDetails.value.telephone;

        // shippingDetails.value.firstname = personalDetails.value.firstname;
        // shippingDetails.value.lastname = personalDetails.value.lastname;
        // shippingDetails.value.telephone = personalDetails.value.telephone;

        if (defaultBillingAddress !== null && defaultShippingAddress !== null) {
          isDiffShippingAddr.value =
            defaultBillingAddress.id !== defaultShippingAddress.id;
        }

        // userBilling.value = loadedUserAddress;
        // userShipping.value = loadedUserAddress;
      } else {
        let loadedCountries = await loadCountries();
        countries.value = loadedCountries;
      }

      // handleCheckSameAddress(sameAsShipping.value);
    });

    return {
      selectedStore,
      sendNotification,

      popupVisible,
      actionCartRedirect,

      isShippingCompany,
      isBillingCompany,

      submitInProgress,

      isLoading,
      goBack,

      shippingMethods,

      isShippingDetailsStepCompleted,
      isBillingDetailsStepCompleted,
      isPersoanlDetailsStepCompleted,

      canMoveForward,

      changeBillingCountry,
      changeShippingCountry,

      countriesList,

      changeShippingDetails,
      shippingDetails,
      handleChangeShippingCompany,

      isDiffShippingAddr,
      handleChangeDiffShipping,
      handleChangeBillingCompany,
      changeGender,

      billingDetails,
      changeBillingDetails,

      personalDetails,
      changePersonalDetails,

      handleAllInfoSubmit,

      handle,
      isShippingStep,
      showBillingInfo,
    };
  },
  methods: {
    checkForValidations() {
      // @ts-ignore
      this.$refs.personalForm.validate().then(async (success) => {
        if (!success) {
          this.sendNotification({
            id: Symbol('form_validation'),
            message: this.$t('Fill up all required fields.') as string,
            type: 'danger',
            icon: 'error',
            persist: true,
          });
        }
      });
    },
  },
});
