export default `
query getPaypalFee($cartId: String!){
  cart(cart_id: $cartId){
    prices {
      paypal_fee {
        value
        currency
      }
    }
  }
}
`;
