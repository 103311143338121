










import { ref, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ThankyouCheckoutStep',
  components: {},
  setup(_, { emit }) {
    const handle = () => {
      emit('nextStep');
    };
    return {
      handle,
    };
  },
});
