



















import {
  ref,
  defineComponent,
  useFetch,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import useCart from '~/modules/checkout/composables/useCart';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import usePaypalPayment from '~/modules/checkout/composables/usePaypalPayment';

export default defineComponent({
  name: 'PaymentCheckoutStep',
  components: {
    VsfPaymentProvider: () =>
      import('~/modules/checkout/components/VsfPaymentProvider.vue'),
  },
  setup(_, { emit }) {
    const isPaymentReady = ref(false);
    const { load, loading } = useCart();
    const router = useRouter();
    const { app } = useContext();
    const { generateToken: generatePaypalToken } = usePaypalPayment();

    useFetch(async () => {
      const validStep = await isPreviousStepValid('billing');
      if (!validStep) {
        await router.push(app.localePath('/checkout/'));
      }
    });

    const handle = async () => {
      // await load();

      emit('nextStep');
    };

    const generateTokenHandler = async () => {
      try {
        let result = await generatePaypalToken();
      } catch (e) {
        console.log('error: ', e);
      }
    };

    return {
      generateTokenHandler,
      loading,
      handle,
      isPaymentReady,
    };
  },
});
