
































































































































import { SfSteps, SfLoader } from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRoute,
  useRouter,
  useFetch,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import useCart from '~/modules/checkout/composables/useCart';
import CartPreview from '~/modules/checkout/components/CartPreview.vue';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';

import InitialCheckoutStep from '~/modules/checkout/components/Steps/InitialCheckoutStep.vue';
import CreateAccountCheckoutStep from '~/modules/checkout/components/Steps/CreateAccountCheckoutStep.vue';
import GuestCheckoutStep from '~/modules/checkout/components/Steps/GuestCheckoutStep.vue';
import PersonalDetailCheckoutStep from '~/modules/checkout/components/Steps/PersonalDetailCheckoutStep.vue';
import PaymentCheckoutStep from '~/modules/checkout/components/Steps/PaymentCheckoutStep.vue';
import SummaryCheckoutStep from '~/modules/checkout/components/Steps/SummaryCheckoutStep.vue';
import ThankyouCheckoutStep from '~/modules/checkout/components/Steps/ThankyouCheckoutStep.vue';
import { useApi } from '~/composables';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    SfSteps,
    SfLoader,
    CartPreview,

    InitialCheckoutStep,
    CreateAccountCheckoutStep,
    PersonalDetailCheckoutStep,
    PaymentCheckoutStep,
    SummaryCheckoutStep,
    ThankyouCheckoutStep,
    GuestCheckoutStep,
  },
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const context = useContext();
    const { path } = route.value;
    const router = useRouter();
    const { cart, load, loading: cartLoading } = useCart();
    const currentStep = computed(() => path.split('/').pop());
    const { query } = useApi();

    const isLoading = ref(true);

    const {
      load: userLoad,
      loading: loadingUser,
      register,
      login,
      user,
      isAuthenticated,
      error: errorUser,
    } = useUser();

    const stepNum = ref(0);
    const lastStep = ref(0);

    const goToStep = (num) => {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }

      stepNum.value = num;
    };

    const guestContinue = ref(false);
    const loginContinue = ref(false);

    const continueAsGuest = () => {
      guestContinue.value = true;
      goToStep(1);
    };

    const loginCheckoutStep = async () => {
      goToStep(2);

      type Form = {
        firstname: string;
        lastname: string;
        email: string;
        recaptchaToken?: string;
        recaptchaInstance?: string;
      };

      if (user.value == null) {
        await userLoad();
      }

      const loginFormData = ref<Form>({
        firstname: user.value.firstname,
        lastname: user.value.lastname,
        email: user.value.email,
      });

      await mergeItem('checkout', { 'user-account': loginFormData.value });
    };

    const goBack = (step) => {
      if (step == 'step1-register') {
        guestContinue.value = false;
        goToStep(1);
      }
      if (step == 'step1-guest') {
        guestContinue.value = true;
        goToStep(1);
      }
      if (step == 'step1') {
        goToStep(1);
      }
    };

    useFetch(() => {
      // if (isAuthenticated.value) {
      //   loginCheckoutStep();
      // }

      let queryParams = route.value.query;
      if (queryParams?.step && queryParams?.step == 'payment') {
        // for payment
        stepNum.value = 3;
      }
    });

    onMounted(async () => {
      await load();

      if (isAuthenticated.value) {
        loginCheckoutStep();
      }
      const products = cartGetters.getItems(cart.value);
      if (products.length === 0) {
        await router.push(app.localePath('/'));
      }

      isLoading.value = false;
    });

    return {
      isLoading,
      goBack,

      cartLoading,
      stepNum,

      guestContinue,
      continueAsGuest,

      goToStep,
    };
  },
  head() {
    return {
      script: [
        {
          src: 'https://js.stripe.com/v3',
        },
      ],
    };
  },
});
