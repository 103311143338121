











































import { SfModal, SfLoader, SfButton } from '@storefront-ui/vue';
import {
  ref,
  defineComponent,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import {
  PaymentIntentOptions,
  StripeEvents,
} from '~/modules/checkout/stripePaymentTypes';
import usePaymentIntent from '~/modules/checkout/composables/useStripePayment/usePaymentIntent';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import useCart from '~/modules/checkout/composables/useCart';
export default defineComponent({
  name: 'StripePayment',
  components: {
    StripeElementPayment: async () => {
      if (process.client) {
        const { StripeElementPayment } = await import('@vue-stripe/vue-stripe');
        return StripeElementPayment;
      }
      return null;
    },
    SfModal,
    SfLoader,
    SfButton,
  },
  emits: ['continue'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { app } = useContext();
    const { load, save } = usePaymentProvider();
    const paymentRef = ref(null);
    const stripeApiKey = process.env.VSF_STRIPE_API_KEY;
    const paymentMethods = ref(null);
    const { cart } = useCart();
    const elementsOptions = ref<PaymentIntentOptions>();
    const confirmParams = ref(null);
    const redirect = 'if_required';
    const clientSecretStatus = ref(false);
    const billingDetails = ref(null);
    const { getClientSecret } = usePaymentIntent();
    const createOptions = {
      fields: {
        billingDetails: 'never',
      },
    };
    const dataLoading = ref(false);
    console.log('clientSecretStatus.value', clientSecretStatus.value);

    onMounted(async () => {
      dataLoading.value = true;
      try {
        let returnUrl = app.localeRoute({ name: 'stripe-order' });

        // Load payment methods for later access
        // paymentMethods.value = await load();
        // VSF BUG https://github.com/vuestorefront/magento2/issues/1389
        const cartId = cart.value.id;

        if (!cartId) return;
        // Get client secret from Magento Stripe module
        const clientSecretResponse = await getClientSecret(cartId);

        if (clientSecretResponse) {
          elementsOptions.value = { clientSecret: clientSecretResponse };

          confirmParams.value = {
            return_url: window.location.origin + returnUrl.fullPath,
          };
          clientSecretStatus.value = true;
          console.log('clientSecretStatus.value', clientSecretStatus.value);
        } else {
          clientSecretStatus.value = false;
        }
      } catch (e) {
        console.log('Error:', e);
      } finally {
        dataLoading.value = false;
      }
    });

    const stripeProcessing = ref(false);

    const setPaymentMethod = async () => {
      try {
        stripeProcessing.value = true;

        let returnUrl = app.localeRoute({ name: 'stripe-order' });

        // Get payment method id from vue stripe element then save selected payment method
        const stripe = paymentRef.value.stripe;
        const elements = paymentRef.value.elements;

        const confirmPayment = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: window.location.origin + returnUrl.fullPath,
            payment_method_data: {
              billing_details: {
                address: {
                  city: cart.value.billing_address.city,
                  country: cart.value.billing_address.country.code,
                  line1: cart.value.billing_address.street[0],
                  line2: cart.value.billing_address.street[1] || '',
                  postal_code: cart.value.billing_address.postcode,
                  state: cart.value.billing_address.region.code,
                },
                email: cart.value.email,
                name:
                  cart.value.billing_address.firstname +
                  ' ' +
                  cart.value.billing_address.lastname,
                phone: cart.value.billing_address.telephone,
              },
            },
          },
        });

        if (confirmPayment.paymentIntent.status === 'succeeded') {
          paymentMethods.value = await save({
            paymentMethod: {
              code: 'stripe_payments',
              stripe_payments: {
                cc_stripejs_token: confirmPayment.paymentIntent.payment_method,
              },
            },
          });

          emit('continue', true);
        }
      } catch (e) {
        console.log('Error: ', e);
      } finally {
        stripeProcessing.value = false;
      }
    };
    const validate = () => {
      // Check if all required fields for the selected payment method in the Payment Element have been filled with potentially valid input
      paymentRef.value.element.on('change', (event: StripeEvents) => {
        if (event.complete) {
          emit('status', event.complete);
        }
      });
    };
    return {
      stripeProcessing,
      dataLoading,
      paymentRef,
      stripeApiKey,
      elementsOptions,
      confirmParams,
      redirect,
      clientSecretStatus,
      billingDetails,
      validate,
      setPaymentMethod,
      createOptions,
    };
  },
});
